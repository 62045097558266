import React from "react";
import AuthService from "../../auth/login/login-auth.service";

const Index = () => {
  React.useEffect(() => {
    AuthService.signinSilentCallback().catch((err) => {
      // Considering this is via an iframe, we can't really do too much
      console.error(err);
    });
  }, []);
  return null;
};

export default Index;
